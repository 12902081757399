import React from "react";

const Location = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        className="ml-[4px] mt-[4px]"
      >
        <path
          d="M8.76382 11.2895C10.315 11.2895 11.5725 10.0321 11.5725 8.48079C11.5725 6.92962 10.315 5.67212 8.76382 5.67212C7.21255 5.67212 5.95508 6.92962 5.95508 8.48079C5.95508 10.0321 7.21255 11.2895 8.76382 11.2895Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
        <path
          d="M1.21853 6.84246C2.99198 -0.953486 14.5419 -0.944483 16.3063 6.85146C17.3416 11.4246 14.4968 15.2956 12.0032 17.6902C10.1938 19.4366 7.33105 19.4366 5.51261 17.6902C3.02799 15.2956 0.183276 11.4156 1.21853 6.84246Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
      </svg>
    </>
  );
};

export default Location;
