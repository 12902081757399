import React from "react";

const HowIt4 = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="15.5" stroke="#FF914D" />
        <circle cx="16" cy="16" r="13" fill="#FF914D" />
      </svg>
    </div>
  );
};

export default HowIt4;
