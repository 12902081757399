import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL_USER}`,
});

// Interceptor to set Authorization header if token is available
api.interceptors.request.use(
  (config) => {
    try {
      const user = localStorage.getItem("root");

      // Check if user data is available in local storage
      if (user) {
        const authData = JSON.parse(user).auth;

        // Check if auth data is available in user data
        if (authData) {
          const token = JSON.parse(authData).token;

          // Check if token is available in auth data
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        }
      }
    } catch (error) {
      console.error("Error while setting Authorization header:", error);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  async function (response) {
    // Do something with response data
    const result = response;
    return result.data;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;

// import axios from "axios";

// const api = axios.create({
//   baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
// });

// // Interceptor to set Authorization header if token is available
// api.interceptors.request.use(
//   (config) => {
//     const user = localStorage.getItem("root");
//     const authData = JSON.parse(user).auth;
//     const token = JSON.parse(authData).token;

//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// api.interceptors.response.use(
//   async function (response) {
//     // Do something with response data
//     const result = response;
//     return result.data;
//   },
//   function (error) {
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

// export default api;
