import React, { useState } from "react";
import DBtn from "../components/DBtn";
import { verifyOtp } from "../services/authService";
import { useNavigate } from "react-router-dom";
import ShowCookies from "../components/ShowCookies";

const VerifyForgotOtp = () => {
  const navigate = useNavigate();
  const lang = localStorage.getItem("i18nextLng");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const email = localStorage.getItem("forgotEmail");
  console.log("email", email);

  const onVerifyOTP = async () => {
    const convertOtp = otp?.map((element) => parseInt(element)).join("");

    try {
      let payload = {
        email: email,
        otp: convertOtp,
      };

      const resp = await verifyOtp(payload);
      console.log("resp", resp);
      navigate("/reset-password");
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (isNaN(value)) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < otp.length - 1 && value !== "") {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  return (
    <div className="container mx-auto p-4 flex justify-center items-center h-screen ">
      <ShowCookies />
      <div className="border-[1px] border-[#FF914D] w-[520px] h-[550px] text-center pt-[30px] rounded-md">
        <div className="flex justify-center">
          <img src="../images/OTP-LOGO.png" alt="" className="pb-[16px]" />
        </div>
        {lang === "en" || lang === "en-US" ? (
          <span className="text-2xl font-bold font-Comfortaa">
            Please enter OTP to verification
          </span>
        ) : (
          <span className="text-2xl font-bold font-Comfortaa">
            Ingrese OTP para la verificación
          </span>
        )}
        <div className="pt-[16px] text-lg text-[#474747] font-Glacial w-[500px] mx-auto">
          {lang === "en" || lang === "en-US" ? (
            <p>
              {`We have sent the code verification to your email address ${email}`}
            </p>
          ) : (
            <p>
              {`Hemos enviado el código de verificación a su dirección de correo electrónico. ${email}`}
            </p>
          )}
        </div>
        <div className="pt-[60px]">
          <div className="flex space-x-4 pl-[65px]">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                type="text"
                value={digit}
                maxLength={1}
                className="w-12 h-12 rounded-md border text-center "
                onChange={(e) => handleChange(e, index)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="sm:pt-[60px]  pt-[30px]">
            <DBtn
              variant="contain"
              size="xlarge"
              onClick={onVerifyOTP}
              startIcon=""
            >
              {lang === "en" || lang === "en-US" ? <>Save</> : <>Guardar</>}
            </DBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyForgotOtp;
