import React from "react";

const CardHolder = () => {
  return (
    <div>
      <svg
        width="21"
        height="26"
        viewBox="0 0 21 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5988 10.6C13.2498 10.6 15.3988 8.45097 15.3988 5.8C15.3988 3.14903 13.2498 1 10.5988 1C7.94786 1 5.79883 3.14903 5.79883 5.8C5.79883 8.45097 7.94786 10.6 10.5988 10.6Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
        <path
          d="M20.2 19.6C20.2 22.5823 20.2 24.9999 10.6 24.9999C1 24.9999 1 22.5823 1 19.6C1 16.6176 5.29806 14.2 10.6 14.2C15.902 14.2 20.2 16.6176 20.2 19.6Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
      </svg>
    </div>
  );
};

export default CardHolder;
