import React, { useState } from "react";
import { toast } from "react-toastify";
import { addToFavorite, removeFromFavorite } from "../services/commonService";

const EmptyHeart = ({ favoriteId, status, onClick }) => {
  const lang = localStorage.getItem("i18nextLng");
  const [isFilled, setIsFilled] = useState(status === 1);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      let resp;
      if (isFilled) {
        // remove from favorite
        resp = await removeFromFavorite(favoriteId);
        console.log("Remove from favorite", favoriteId);
      } else {
        // add to favorite
        resp = await addToFavorite(favoriteId);
        console.log("Added to favorite", favoriteId);
      }

      if (resp?.success) {
        toast.success(
          lang === "en" || lang === "en-US"
            ? resp?.message?.en
            : resp?.message?.es
        );
        setIsFilled(!isFilled);
      } else {
        toast.error(resp?.message?.en);
      }

      if (resp?.isAuth === false) {
        toast.error("Please Login");
      }
    } catch (error) {
      console.error("error :", error);
    } finally {
      setIsLoading(false);
      onClick();
    }
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={handleClick}
        className="heart-button transition delay-150"
        disabled={isLoading}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={isFilled ? "#FF914D " : "transparent"}
          viewBox="0 0 24 24"
          stroke="currentColor"
          className={`h-[23px] w-[24px] pb-[10px] pr-[10px]  transition delay-150`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C15.09 3.81 16.76 3 18.5 3 21.58 3 24 5.42 24 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
          />
        </svg>
      </button>
    </div>
  );
};

export default EmptyHeart;
