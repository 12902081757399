import React from "react";

const HowIt1 = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="15.5" stroke="#FF914D" />
        <path
          d="M29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29C12.5522 29 9.24559 27.6304 6.80761 25.1924C4.36964 22.7544 3 19.4478 3 16L16 16H29Z"
          fill="#FF914D"
        />
      </svg>
    </div>
  );
};

export default HowIt1;
