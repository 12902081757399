import React from "react";

const Amex = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="9"
        viewBox="0 0 40 9"
        fill="none"
        className="border-[0.5px] border-[#ABABAB] w-[60px] h-[24px] px-[6px]"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.65305 4.95209L5.22796 1.47948L3.81102 4.95209H6.65305ZM20.3217 8.71082L20.3139 1.88856L17.2947 8.71082H15.4666L12.4396 1.88252V8.71082H8.20479L7.40475 6.76787H3.06957L2.26139 8.71082H0L3.72852 0H6.82199L10.3632 8.24735V0H13.7614L16.4863 5.90924L18.9894 0H22.456V8.71082H20.3217ZM25.7697 6.92839V5.19484H30.3408V3.41716H25.7697V1.83311H30.9898L33.2672 4.37221L30.8889 6.92839H25.7697ZM39.8767 8.74417H37.1712L34.6074 5.85983L31.943 8.74417H23.6956V0.0312507H32.0699L34.6316 2.88721L37.28 0.0312507H40L35.9537 4.38771L39.8767 8.74417Z"
          fill="#2557D6"
        />
      </svg>
    </div>
  );
};

export default Amex;
