import React from "react";

const Host = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 14 18"
        fill="none"
      >
        <path
          d="M6.84317 9.7835C7.38268 9.7835 7.9169 9.88977 8.41533 10.0962C8.91377 10.3027 9.36666 10.6053 9.74814 10.9868C10.1296 11.3683 10.4322 11.8212 10.6387 12.3196C10.8452 12.818 10.9514 13.3523 10.9514 13.8918C10.9514 14.4313 10.8452 14.9655 10.6387 15.4639C10.4322 15.9623 10.1296 16.4152 9.74814 16.7967C9.36666 17.1782 8.91377 17.4808 8.41533 17.6873C7.9169 17.8937 7.38268 18 6.84317 18C5.7536 18 4.70865 17.5672 3.9382 16.7967C3.16776 16.0263 2.73493 14.9813 2.73493 13.8918C2.73493 12.8022 3.16776 11.7572 3.9382 10.9868C4.70865 10.2163 5.7536 9.7835 6.84317 9.7835ZM6.84317 10.9573C6.06491 10.9573 5.31851 11.2665 4.76819 11.8168C4.21788 12.3671 3.90871 13.1135 3.90871 13.8918C3.90871 14.67 4.21788 15.4164 4.76819 15.9667C5.31851 16.517 6.06491 16.8262 6.84317 16.8262C7.62144 16.8262 8.36783 16.517 8.91815 15.9667C9.46847 15.4164 9.77764 14.67 9.77764 13.8918C9.77764 13.1135 9.46847 12.3671 8.91815 11.8168C8.36783 11.2665 7.62144 10.9573 6.84317 10.9573ZM12.5184 4.66002e-06C12.8151 -0.000830727 13.101 0.110679 13.3188 0.31211C13.5365 0.513541 13.67 0.789952 13.6922 1.08576V4.92403C13.6908 5.12518 13.6376 5.32258 13.538 5.49729C13.4383 5.672 13.2954 5.81816 13.1229 5.92175L13.0408 5.9687L7.36551 8.80926C7.21947 8.88127 7.06005 8.92211 6.89738 8.92918C6.73471 8.93626 6.57235 8.90941 6.42061 8.85034L6.32084 8.80926L0.645588 5.9687C0.465499 5.87886 0.311534 5.74423 0.19848 5.57773C0.0854254 5.41123 0.0170796 5.21846 6.03454e-06 5.01794V1.17379C-0.000941609 0.878089 0.109753 0.592926 0.30996 0.375309C0.510167 0.157693 0.785134 0.0236593 1.07989 4.66002e-06H1.16792H12.5184ZM12.5184 1.17379H1.16792V4.92403L6.84317 7.75872L12.5184 4.92403V1.17379Z"
          fill="#FF914D"
        />
      </svg>
    </>
  );
};

export default Host;
