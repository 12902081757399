import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import {
  cancelGuestBooking,
  cancelHostBooking,
  completeGuestBooking,
  completeHostBooking,
  upcomingGuestBooking,
  upcomingHostBooking,
} from "../services/bookingService";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import DishBooking from "../components/DishBooking";

// import { PER_PAGE } from "../utils/constants/commonConstant";

const Tab = ({ label, onClick, isActive }) => {
  const activeClass = isActive ? "border-b-[1px] border-[#FF914D]" : "";
  return (
    <button onClick={onClick} className={`px-4 py-2 ${activeClass}`}>
      {label}
    </button>
  );
};

const MyBookings = () => {
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const [upcomingList, setUpcomingList] = useState({
    bookings: [],
    favorites: [],
  });
  const [completeList, setCompleteList] = useState([]);
  const [cancelList, setCancelList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);

  const updateLoadingState = (loading) => {
    setLoading(loading);
  };

  useEffect(() => {
    setFlag(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setFlag(false);
      try {
        const payload = {
          perPage: 1000,
          pageNo: 1,
        };

        switch (activeTab) {
          case 0:
            try {
              const upcomingResp =
                user?.role === "guest"
                  ? await upcomingGuestBooking(payload)
                  : await upcomingHostBooking(payload);
              if (upcomingResp?.success) {
                setLoading(true);
                user?.role === "guest"
                  ? setUpcomingList({
                      ...upcomingList,
                      bookings: upcomingResp?.data?.bookings,
                      favorites: upcomingResp?.data?.favorites,
                    })
                  : setUpcomingList({
                      ...upcomingList,
                      bookings: upcomingResp?.data?.bookings,
                      favorites: upcomingResp?.data?.favorites,
                    });
              }
            } catch (error) {
              console.log("error", error);
              setUpcomingList({
                bookings: [],
                favorites: [],
              });
            }

            break;
          case 1:
            try {
              const completeResp =
                user?.role === "guest"
                  ? await completeGuestBooking(payload)
                  : await completeHostBooking(payload);
              if (completeResp?.success) {
                setCompleteList(completeResp.data.bookings);
              }
            } catch (error) {
              console.log("error", error);
            }

            break;
          case 2:
            try {
              const cancelResp =
                user?.role === "guest"
                  ? await cancelGuestBooking(payload)
                  : await cancelHostBooking(payload);
              if (cancelResp?.success) {
                setCancelList(cancelResp.data);
              }
            } catch (error) {
              console.log("error", error);
            }

            break;
          default:
            break;
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [activeTab, flag]);

  useEffect(() => {
    // reset scroll position when component updates (page changes)
    window.scrollTo(0, 0);
  }, [flag]);

  console.log("upcomingList", upcomingList);
  return (
    <>
      <div className="container font-Comfortaa mx-auto w-full">
        <div className="border-b-[1px] border-[#FF914D] container mx-auto">
          <div className="container mx-auto">
            <Header />
          </div>
        </div>

        <div className="container mx-auto my-8">
          <div className="flex">
            {[
              t("bookingPage.upcoming"),
              t("bookingPage.completed"),
              t("bookingPage.cancelled"),
            ].map((tab, index) => (
              <Tab
                key={index}
                label={tab}
                onClick={() => setActiveTab(index)}
                isActive={activeTab === index}
              />
            ))}
          </div>
          {loading ? (
            <div className="flex justify-center items-center m-auto h-[50vh]">
              <Loader />
            </div>
          ) : (
            <div className="p-4">
              <div className="container grid sm:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
                {activeTab === 0 &&
                  upcomingList?.bookings?.map((item, index) => (
                    <Link to={`/dish-details/${item?.listingId}`} key={index}>
                      <DishBooking
                        dish={item}
                        onCancel={true}
                        updateLoadingState={updateLoadingState}
                        setFlag={setFlag}
                      />
                    </Link>
                  ))}
                {activeTab === 1 &&
                  completeList
                    ?.filter(
                      (item, index, self) =>
                        self.findIndex((t) => t._id === item._id) === index
                    )
                    .map((item, index) => (
                      <Link to={`/dish-details/${item?.listingId}`} key={index}>
                        <DishBooking dish={item} ifCompleted={true} />
                      </Link>
                    ))}

                {activeTab === 2 &&
                  cancelList
                    ?.filter(
                      (item, index, self) =>
                        self.findIndex((t) => t._id === item._id) === index
                    )
                    .map((item, index) => (
                      <Link to={`/dish-details/${item?.listingId}`} key={index}>
                        <DishBooking dish={item} ifCancelled={true} />
                      </Link>
                    ))}
              </div>
              {activeTab === 0 && upcomingList?.bookings?.length === 0 && (
                <div className="flex justify-center w-full">
                  <div>
                    <div>
                      <img
                        src="../images/Data-Not-Found.jpg"
                        alt=""
                        className="w-32 flex mx-auto"
                      />
                    </div>
                    <div className=" w-full">
                      {lang === "en" || lang === "en-US" ? (
                        <p className="text-center pt-[30px] text-red-500">
                          There are no upcoming bookings
                        </p>
                      ) : (
                        <p className="text-center pt-[30px] text-red-500">
                          No hay reservas próximas
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 1 && completeList.length === 0 && (
                <div className="flex justify-center w-full">
                  <div>
                    <div>
                      <img
                        src="../images/Data-Not-Found.jpg"
                        alt=""
                        className="w-32 flex mx-auto"
                      />
                    </div>
                    <div className=" w-full">
                      {lang === "en" || lang === "en-US" ? (
                        <p className="text-center pt-[30px] text-red-500">
                          There are no completed bookings
                        </p>
                      ) : (
                        <p className="text-center pt-[30px] text-red-500">
                          No hay reservas completadas
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 2 && cancelList.length === 0 && (
                <div className="flex justify-center w-full">
                  <div>
                    <div>
                      <img
                        src="../images/Data-Not-Found.jpg"
                        alt=""
                        className="w-32 flex mx-auto"
                      />
                    </div>
                    <div className=" w-full">
                      {lang === "en" || lang === "en-US" ? (
                        <p className="text-center pt-[30px] text-red-500">
                          There are no cancelled bookings
                        </p>
                      ) : (
                        <p className="text-center pt-[30px] text-red-500">
                          No hay reservas anuladas
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MyBookings;
