import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Title from "../components/Title";
import { useTranslation } from "react-i18next";
import ShowCookies from "../components/ShowCookies";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { getguestTOC } from "../services/policyServices";
const TermsConditionGuest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const lang = localStorage.getItem("i18nextLng");
  const goBack = () => {
    setLoading(true);
    navigate(-1);

    setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
    }, 100);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await getguestTOC();
      if (resp?.success) {
        setData(resp?.data[0]);
      }
    } catch (err) {
      console.log("Error fetching data: ", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [lang, navigate]);

  console.log("fetching data guest:", data);
  return (
    <>
      <div className="container mx-auto">
        <ShowCookies />

        <Header />
        <div
          onClick={goBack}
          className="flex gap-2 items-center font-Glacial font-bold mb-4 cursor-pointer"
        >
          <IoArrowBackOutline size={20} />
          <p>{t("termsCondition.back")}</p>
        </div>
        <div className="font-bold">
          <Title title={t("termsCondition.termsConditionGuest")} />
        </div>
        {loading ? (
          <>
            <div className="flex justify-center items-center m-auto h-[50vh]">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {lang === "en" || lang === "en-US" ? (
              <>
                {/* ENGLISH */}
                <div className="font-Glacial text-xl">
                  <div className="exception-div1 exception-div2 ">
                    {data?.translations?.en?.policyDetails?.text && (
                      <div>
                        {" "}
                        {parse(data?.translations?.en?.policyDetails?.text)}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* SPANISH */}
                <div className="font-Glacial text-xl">
                  <div className="exception-div1 exception-div2">
                    {data?.policyDetails?.text && (
                      // <div dangerouslySetInnerHTML={createMarkup(data?.termsAndConditionHost)} />
                      <div> {parse(data?.policyDetails?.text)}</div>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default TermsConditionGuest;
