import React from "react";

const Bank = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
      >
        <path
          d="M10.3029 1.04909L17.6665 3.99452C17.9529 4.10907 18.182 4.4527 18.182 4.75543V7.47183C18.182 7.92183 17.8138 8.29001 17.3638 8.29001H2.63654C2.18654 8.29001 1.81836 7.92183 1.81836 7.47183V4.75543C1.81836 4.4527 2.04745 4.10907 2.33381 3.99452L9.69745 1.04909C9.86109 0.983636 10.1393 0.983636 10.3029 1.04909Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.182 17.2903H1.81836V14.8358C1.81836 14.3858 2.18654 14.0176 2.63654 14.0176H17.3638C17.8138 14.0176 18.182 14.3858 18.182 14.8358V17.2903Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.45508 14.0173V8.29004"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.72656 14.0173V8.29004"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 14.0173V8.29004"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.2734 14.0173V8.29004"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.5449 14.0173V8.29004"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 17.29H19"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.0007 6.24458C10.6785 6.24458 11.228 5.69512 11.228 5.01731C11.228 4.33951 10.6785 3.79004 10.0007 3.79004C9.32293 3.79004 8.77344 4.33951 8.77344 5.01731C8.77344 5.69512 9.32293 6.24458 10.0007 6.24458Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default Bank;
