import React from "react";

const Visa = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="12"
        viewBox="0 0 40 12"
        fill="none"
        className="border-[0.5px] border-[#ABABAB] w-[60px] h-[24px] px-[6px]"
      >
        <path
          d="M13.8369 11.863L15.818 0.237549H18.9867L17.0042 11.863H13.8369Z"
          fill="#0E4595"
        />
        <path
          d="M28.4525 0.488266C27.8248 0.252744 26.8411 0 25.6126 0C22.4815 0 20.2761 1.57674 20.2572 3.83653C20.2396 5.50698 21.8318 6.43886 23.0337 6.99494C24.2672 7.56486 24.6818 7.92824 24.6759 8.437C24.668 9.21631 23.6909 9.57221 22.78 9.57221C21.5119 9.57221 20.8381 9.39601 19.7974 8.96208L19.389 8.77728L18.9443 11.3797C19.6845 11.7043 21.053 11.9854 22.4741 12C25.805 12 27.9673 10.4413 27.9919 8.02807C28.0038 6.70556 27.1596 5.69921 25.3314 4.86948C24.2239 4.33169 23.5456 3.97282 23.5528 3.42826C23.5528 2.94504 24.1269 2.42833 25.3675 2.42833C26.4036 2.41223 27.1543 2.6382 27.7391 2.87372L28.0231 3.00787L28.4525 0.488266Z"
          fill="#0E4595"
        />
        <path
          d="M36.6066 0.237305H34.1582C33.3997 0.237305 32.832 0.444322 32.4989 1.20137L27.793 11.8552H31.1204C31.1204 11.8552 31.6644 10.4228 31.7874 10.1082C32.151 10.1082 35.3835 10.1132 35.8456 10.1132C35.9403 10.5202 36.2311 11.8552 36.2311 11.8552H39.1714L36.6066 0.237305ZM32.7218 7.74409C32.984 7.07428 33.9844 4.49428 33.9844 4.49428C33.9657 4.52522 34.2445 3.82121 34.4045 3.38473L34.6187 4.38703C34.6187 4.38703 35.2254 7.16205 35.3522 7.74391H32.7218V7.74409Z"
          fill="#0E4595"
        />
        <path
          d="M0.0404795 0.237549L0 0.479382C1.25261 0.782603 2.37138 1.22147 3.35087 1.76742L6.16251 11.8444L9.51511 11.8407L14.5037 0.237549H11.1469L8.04475 8.16526L7.7143 6.55409C7.69882 6.50431 7.68199 6.45444 7.66477 6.40446L6.58632 1.21903C6.39457 0.48289 5.83827 0.263203 5.15006 0.237549H0.0404795Z"
          fill="#0E4595"
        />
      </svg>
    </div>
  );
};

export default Visa;
