import React from "react";

const Title = ({title = ""}) => {
  return (
    <>
      <div className="container mx-auto">
        <p className="text-2xl font-Glacial text-[#111]">{title}</p>
        <p className="flex">
          <span
            className={`inline-block h-[2px] w-[80px] bg-[#FF914D] `}
          ></span>
        </p>
      </div>
    </>
  );
};

export default Title;
