import React from "react";

const SendBtn = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="26"
        viewBox="0 0 28 26"
        fill="none"
      >
        <path
          d="M13.4219 13.004H3.93282M3.66386 14.2456L2.09614 18.92C1.23751 21.4802 0.80819 22.7602 1.11629 23.5484C1.38383 24.2331 1.95847 24.752 2.66755 24.9496C3.48406 25.177 4.71732 24.6231 7.18384 23.5153L22.9965 16.4126C25.404 15.3311 26.6078 14.7905 26.9798 14.0393C27.303 13.3868 27.303 12.621 26.9798 11.9684C26.6078 11.2174 25.404 10.6767 22.9965 9.59528L7.15656 2.48042C4.69747 1.37587 3.46795 0.823589 2.65224 1.05015C1.94384 1.2469 1.36926 1.76451 1.10078 2.44781C0.79162 3.23461 1.21635 4.51191 2.06582 7.06651L3.6669 11.8815C3.81279 12.3202 3.88575 12.5397 3.91453 12.764C3.94009 12.9632 3.93983 13.1647 3.91377 13.3637C3.88439 13.588 3.81088 13.8071 3.66386 14.2456Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default SendBtn;
