import React from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

const LocationCard = ({
  latitude,
  longitude,
  city,
  country,
  addressLine1,
  addressLine2,
  postalcode,
  state,
  cityEn,
  countryEn,
  addressLine1En,
  addressLine2En,
  postalcodeEn,
  stateEn,
}) => {
  const { t } = useTranslation();
  const mapsAPI = process.env.REACT_APP_MAP_API_KEY;
  const position = { lat: latitude, lng: longitude };
  const lang = localStorage.getItem("i18nextLng");
  // console.log(postalcode);
  return (
    <>
      <div className=" my-10">
        <div>
          <p className="text-[#000] text-lg font-Glacial flex items-center gap-2">
            <HiOutlineLocationMarker className="text-primary " />
            {t("dishDetail.whereYouwillBe")}
          </p>
        </div>
        <div className="relative w-full h-[300px] rounded-lg my-6">
          <APIProvider apiKey={mapsAPI}>
            <Map defaultCenter={position} defaultZoom={10}>
              <Marker position={position} />
            </Map>
          </APIProvider>
        </div>
        {lang === "en" || lang === "en-US" ? (
          <div className="">
            <div className="flex items-center  gap-x-1 ">
              <HiOutlineLocationMarker className="text-primary w-4" />
              <p className="font-Glacial text-md text-[#474747] capitalize ">
                {" "}
                {cityEn}, {countryEn}
              </p>
            </div>
            <p className="capitalize text-md text-[#ABABAB] font-Glacial xl:w-[700px] w-auto pt-[10px]">
              {addressLine1En} {addressLine2En}, {countryEn}, {cityEn},{" "}
              {stateEn},{postalcode}
            </p>
          </div>
        ) : (
          <div className="">
            <div className="flex items-center  gap-x-1 ">
              <HiOutlineLocationMarker className="text-primary w-4" />
              <p className="font-Glacial text-md text-[#474747] capitalize ">
                {" "}
                {city}, {country}
              </p>
            </div>
            <p className="capitalize text-md text-[#ABABAB] font-Glacial xl:w-[700px] w-auto pt-[10px]">
              {addressLine1} {addressLine2}, {country}, {city},{state},
              {postalcode}.
            </p>
          </div>
        )}
        <div className="h-[1px] w-full bg-[#ABABAB] mt-4 mb-6 "></div>
      </div>
    </>
  );
};

export default LocationCard;
