import React from "react";

const Logout = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M11.3984 12.9999C11.3887 14.7399 11.3116 15.6822 10.6969 16.2969C9.99393 16.9998 8.86258 16.9998 6.59988 16.9998L5.79989 16.9998C3.5372 16.9998 2.40577 16.9998 1.70289 16.2969C0.999941 15.5939 0.999941 14.4626 0.999941 12.1999L0.999942 5.79995C0.999942 3.53723 0.999942 2.40587 1.7029 1.70294C2.40577 1 3.5372 1 5.79989 1L6.59989 1C8.86258 1 9.99393 1 10.6969 1.70294C11.3116 2.31763 11.3887 3.25995 11.3984 4.99996"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M6.6001 8.99958L17 8.99959M17 8.99959L14.2 11.3996M17 8.99959L14.2 6.59961"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default Logout;
