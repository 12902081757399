import React, { useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdStarRate } from "react-icons/md";
import EmptyHeart from "../svg/EmptyHeart";
import TruncateWords from "./TruncateWords";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { GiMeal } from "react-icons/gi";
import { format } from "date-fns";
import { cancelGuestBookingById } from "../services/bookingService";
import { toast } from "react-toastify";
import Modal from "./Modal";
import CompleteBookings from "../svg/CompleteBookings";
import CancelledBooking from "../svg/CancelledBooking";
import { useTranslation } from "react-i18next";

const DishBooking = ({
  dish,
  onCancel,
  updateLoadingState,
  setFlag,
  ifCompleted,
  ifCancelled,
}) => {
  // const disatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const startDate = new Date(dish?.bookingStartDate);
  const endDate = new Date(dish?.bookingEndDate);
  const formatDate = (date) => {
    return format(date, "dd-MM-yyyy");
  };
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const handleCancel = async (e) => {
    e.preventDefault();
    console.log("Cancel button clicked for dish ID:", dish?._id);
    try {
      updateLoadingState(true);
      const resp = await cancelGuestBookingById(dish?._id);
      console.log(resp);
      // disatch(getId(dish?._id));
      toast.success(
        lang === "en" || lang === "en-US"
          ? resp?.message?.en
          : resp?.message?.es
      );
      setFlag(true);
      updateLoadingState(false);
      if (typeof onCancel === "function") onCancel();
    } catch (error) {
      console.log("Error:", error);
      updateLoadingState(false);
    }
  };

  return (
    <>
      <div className="lg:w-[430px] h-[250px] lg:m-5 p-4 relative  shadow-[0_3px_10px_rgb(0,0,0,0.2)] first-letter rounded-[10px] shadow-[#E6E6E6E5] ">
        <div className="flex justify-between">
          <div>
            <p className="sm:w-[300px] capitalize h-[60px] text-title font-Glacial text-2xl text-[#111111] ">
              <TruncateWords
                maxWidth="350px"
                maxLines={2}
                text={
                  lang === "en" || lang === "en-US"
                    ? dish?.translations?.en?.dishTitle
                      ? dish?.translations?.en?.dishTitle
                      : dish?.dishTitle
                    : dish?.dishTitle
                }
              />
            </p>
          </div>
          <div
            onClick={(e) => e.preventDefault()}
            className="w-[32px] h-[32px] bg-[#FF914D1A] p-[9px] text-primary rounded-full "
          >
            <EmptyHeart
              status={dish?.favoriteStatus}
              favoriteId={dish?.listingId}
              className="text-sm cursor-pointer"
              onClick={() => console.log()}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div>
            <div className="flex items-center  justify-between w-[270px] ">
              <div className="flex items-center gap-x-1">
                <HiOutlineLocationMarker className="text-primary" />
                <p className="font-Glacial text-lg text-[#474747] capitalize ">
                  {lang === "en" || lang === "en-US" ? (
                    <>
                      {dish?.translations?.en?.address?.city},{" "}
                      {dish?.translations?.en?.address?.country}
                    </>
                  ) : (
                    <>
                      {dish?.address?.city}, {dish?.address?.country}
                    </>
                  )}
                  {/* {dish?.address?.city}, {dish?.address?.country} */}
                </p>
              </div>
              <span className="flex items-center justify-center text-primary text-[18px] font-Comfortaa-SemiBold">
                ${dish?.dishPrice}
              </span>
            </div>
            {dish?.hostDetails?.ratings ? (
              <div className="flex items-center gap-x-1 pt-1">
                <MdStarRate className="text-primary " />
                <p className="text-[16px] text-[#757575] font-Glacial">
                  {dish?.hostDetails?.ratings}
                </p>
              </div>
            ) : (
              <></>
            )}

            <div className=" justify-between pt-[12px] gap-2">
              <div className="flex gap-2 items-center  font-Glacial">
                <FaRegCalendarAlt className="text-primary" />
                <span className="text-[14px] text-[#ABABAB] ">
                  {`${formattedStartDate} - ${formattedEndDate}`}
                </span>
              </div>

              <div className="flex gap-2 items-center  font-Glacial">
                <IoMdPerson className="text-primary " />
                <span className="text-[14px] text-[#ABABAB] ">
                  {dish?.noOfGuests} {t("dishDetail.guests")}
                </span>
              </div>

              <div className="flex gap-2 items-center  font-Glacial">
                <GiMeal className="text-primary " />
                <span className="text-[14px] text-[#ABABAB] ">
                  {dish?.bookingQuantity} {t("dishDetail.dishes")}
                </span>
              </div>
            </div>
          </div>

          <div className=" flex items-center ">
            <img
              className="w-[120px] h-[120px] object-cover rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.1)] first-letter  shadow-[#FF914D33]"
              src={dish?.mainImage}
              alt=""
            />
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="flex items-center justify-center  gap-x-2 capitalize">
            {dish?.hostDetails?.firstName || dish?.hostDetails?.hostFullName ? (
              <>
                <img
                  className="w-[26px] h-[26px] rounded-full object-cover "
                  src={dish?.hostDetails?.profileImage}
                  alt=""
                />
              </>
            ) : (
              <></>
            )}
            {dish?.hostDetails?.hostFullName ? (
              <p className="font-Glacial text-sm text-[#BABABA] line-clamp-1">
                {dish?.hostDetails?.hostFullName}
              </p>
            ) : (
              <p className="font-Glacial text-sm text-[#BABABA] line-clamp-1">
                {lang === "en" || lang === "en-US" ? (
                  <>
                    {dish?.translations?.en?.hostDetails?.firstName}{" "}
                    {dish?.translations?.en?.hostDetails?.lastName}
                  </>
                ) : (
                  <>
                    {dish?.hostDetails?.firstName} {dish?.hostDetails?.lastName}
                  </>
                )}
              </p>
            )}
          </div>

          {onCancel && (
            <div className="flex items-center justify-center">
              {lang === "en" || lang === "en-US" ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowConfirmationModal(true);
                  }}
                  className="border border-primary w-[80px] h-[30px] rounded-lg font-Glacial text-sm text-primary hover:bg-primary hover:text-white transition delay-150"
                >
                  Cancel
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowConfirmationModal(true);
                  }}
                  className="border border-primary w-[80px] h-[30px] rounded-lg font-Glacial text-sm text-primary hover:bg-primary hover:text-white transition delay-150"
                >
                  Cancelar
                </button>
              )}
            </div>
          )}

          {ifCompleted && (
            <div className="flex items-center justify-center pr-2 gap-2">
              <CompleteBookings />
              {/* <div className="flex items-center justify-center">
                {lang === "en" || lang === "en-US" ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      // setShowConfirmationModal(true);
                    }}
                    className="border border-primary w-[80px] h-[30px] rounded-lg font-Glacial text-sm text-primary hover:bg-primary hover:text-white transition delay-150"
                  >
                    Rate
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      // setShowConfirmationModal(true);
                    }}
                    className="border border-primary w-[80px] h-[30px] rounded-lg font-Glacial text-sm text-primary hover:bg-primary hover:text-white transition delay-150"
                  >
                    Tasa
                  </button>
                )}
              </div> */}
            </div>
          )}

          {ifCancelled && (
            <div className="flex items-center justify-center mt-2 pr-2">
              <CancelledBooking />
            </div>
          )}

          {showConfirmationModal && (
            <Modal
              title={t("bookingPage.cancelTitle")}
              width={500}
              onClose={(e) => {
                e.preventDefault();
                setShowConfirmationModal(false);
              }}
            >
              <p className="my-5 text-center">
                {t("bookingPage.cancelSubTitle")}
              </p>
              <div className="w-[467px] flex items-center justify-between border border-primary text-primary rounded-sm mt-2">
                <button
                  onClick={handleCancel}
                  className="text-center flex items-center justify-center  border-primary w-full px-2 py-2 cursor-pointer "
                >
                  {t("bookingPage.yesCancel")}
                </button>
                <button
                  className="text-center flex items-center justify-center border-l border-primary w-full px-2 py-2 bg-primary bg-opacity-10 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowConfirmationModal(false);
                  }}
                >
                  {t("bookingPage.noCancel")}
                </button>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default DishBooking;
