import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  signedIn: false,
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    onSignInSuccess: (state, action) => {
      state.signedIn = true;
      state.token = action.payload;
    },
    onSignOutSuccess: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.token = "";
    },
    currentLocation: (state, action) => {
      state.currentLocation = action.payload;
    },
  },
});

export const { setUser, onSignInSuccess, onSignOutSuccess, currentLocation } =
  authSlice.actions;

export default authSlice.reducer;
