import React from "react";

const MMPaymentDetails = () => {
  return (
    <div>
      <svg
        width="24"
        height="26"
        viewBox="0 0 24 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 1V4.6"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.5996 1V4.6"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.59961 9.50806H21.9996"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.6 8.80005V19C22.6 22.6 20.8 25 16.6 25H7C2.8 25 1 22.6 1 19V8.80005C1 5.20005 2.8 2.80005 7 2.80005H16.6C20.8 2.80005 22.6 5.20005 22.6 8.80005Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.7949 15.04H11.8057"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.35352 15.04H7.36429"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.35352 18.6399H7.36429"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default MMPaymentDetails;
