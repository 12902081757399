import React, { useState } from "react";
import logoimg from "../image/signup-logo.png";
import useAuth from "../utils/hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Password from "../svg/Password";
import ForgotInput from "../components/ForgotInput";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";

const LogIn = ({ type, handleSignupModalOpen }) => {
  const { t } = useTranslation();
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const lang = localStorage.getItem("i18nextLng");
  const inputType = type === "password" && passwordVisible ? "text" : type;
  const navigate = useNavigate();
  const { userSignIn } = useAuth();

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [errorsEs, setErrorsEs] = useState({});

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const newErrorsEs = {};

    // Validate email
    if (!userData.email) {
      newErrors.email = "Email is required.";
      newErrorsEs.email = "Correo electronico es requerido.";
      isValid = false;
    }

    if (!isEmail(userData.email) && userData.email) {
      newErrors.emailvalid = "Enter valid email address";
      newErrorsEs.emailvalid = "Introduzca un correo electronico valido.";
      isValid = false;
    }

    // Validate password
    if (!userData.password) {
      newErrors.password = "Password is required.";
      newErrorsEs.password = "Se requiere contraseña.";
      isValid = false;
    }
    setErrorsEs(newErrorsEs);
    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      const payload = {
        email: (userData?.email).toLowerCase(),
        password: userData?.password,
      };

      try {
        const resp = await userSignIn(payload);
        console.log("resp?.message?.en", resp);
        if (resp?.success) {
          // console.log("resp?.message?.en", resp?.message);
          toast.success(
            lang === "en" || lang === "en-US"
              ? resp?.message?.en
              : resp?.message?.es
          );
          navigate("/");
        } else {
          toast.error(
            lang === "en" || lang === "en-US"
              ? resp?.message?.en
              : resp?.message?.es
          );
        }
      } catch (err) {
        console.log("err", err);
        toast.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      // Form is not valid, display error messages
    }
  };

  // console.log("Errors in English: ", errors);
  // console.log("Errors in Spanish: ", errorsEs);

  return (
    <>
      <div className="container w-[524px] h-[674px] mx-auto bg-[#FFF] rounded-[20px] px-[24px] ">
        <div className="pt-[40px]">
          <div className="w-[100px] h-[100px] mx-auto">
            <img src={logoimg} alt="" />
          </div>
          <div>
            <h3 className="font-Comfortaa text-[24px] font-bold text-[#111] text-center pt-[16px]">
              {t("homePage.welcome")}
            </h3>
            <h5 className="text-[#757575] font-Glacial Indifference text-[16px] text-center pt-[4px]">
              {t("homePage.loginAccount")}
            </h5>
          </div>
        </div>

        <form>
          <div>
            <div className="relative flex items-center pt-4 pb-2">
              <div className="absolute text-2xl text-primary p-3 flex items-center justify-center only: w-[70px] h-[70px] bg-transparent border-r">
                <img src="../images/Loginform/maile.svg" alt="" />
              </div>
              <input
                id="email"
                name="email"
                className="w-full focus:ring-2 focus:ring-primary focus:border-primary transition delay-150 mx-auto h-[70px] border border-gray-300 outline-none rounded-lg ps-[84px] pe-4 font-Glacial  sm:text-[20px] text-[16px]  placeholder:text-[#ABABAB]"
                type="email"
                placeholder={t("homePage.enterEmail")}
                onChange={handleInputChange}
              />
            </div>
            {lang === "en" || lang === "en-US" ? (
              <>
                {errors.email && userData?.email === "" && (
                  <div className="text-red-300 ml-20 font-Glacial">
                    {errors.email}
                  </div>
                )}

                {errors.emailvalid && (
                  <div className="text-red-300 ml-20 font-Glacial">
                    {errors.emailvalid}
                  </div>
                )}
              </>
            ) : (
              <>
                {errorsEs.email && userData?.email === "" && (
                  <div className="text-red-300 ml-20 font-Glacial">
                    {errorsEs.email}
                  </div>
                )}

                {errorsEs.emailvalid && (
                  <div className="text-red-300 ml-20 font-Glacial">
                    {errorsEs.emailvalid}
                  </div>
                )}
              </>
            )}
          </div>

          {/* password */}

          <div className="pt-4 pb-2">
            <div className="absolute z-50 text-2xl text-primary p-3 flex items-center justify-center only: w-[70px] h-[70px] bg-transparent border-r">
              <Password />
            </div>
            <ForgotInput
              id="password"
              name="password"
              className="w-full focus:ring-2 focus:ring-primary focus:border-primary transition delay-150 mx-auto h-[70px] border border-gray-300 outline-none rounded-lg ps-[84px] pe-[60px] font-Glacial  sm:text-[20px] text-[16px]  placeholder:text-[#ABABAB]"
              type="password"
              placeholder={t("homePage.enterPassword")}
              onChange={handleInputChange}
            />
            {lang === "en" || lang === "en-US" ? (
              <>
                {" "}
                {errors.password && userData?.password === "" && (
                  <div className="text-red-300 ml-20 font-Glacial">
                    {errors.password}
                  </div>
                )}
              </>
            ) : (
              <>
                {" "}
                {errorsEs.password && userData?.password === "" && (
                  <div className="text-red-300 ml-20 font-Glacial">
                    {errorsEs.password}
                  </div>
                )}
              </>
            )}
          </div>

          {/* forgot password */}
          <div className="flex justify-end pt-[16px]">
            {/* <a
              className="text-[#ABABAB] font-Glacial Indifference text-[16px] "
              href="/forgot-password"
            > */}
            <Link
              className="text-[#ABABAB] font-Glacial Indifference text-[16px] "
              to="/forgot-password"
            >
              {t("homePage.forgotPassword")}
            </Link>

            {/* </a> */}
          </div>

          <div className="flex justify-center pt-[50px] ">
            {loading ? (
              <>
                <div className=" border-primary bg-white text-[#FFF]  border-[3px] text-xs rounded-full w-[396px] py-2  flex mx-auto items-center justify-center ">
                  <Loader size={38} />
                </div>
              </>
            ) : (
              <button
                variant="contain"
                type="submit"
                onClick={handleSubmit}
                className="bg-primary text-white py-[18px] w-[396px] rounded-full mx-auto  font-Comfortaa-Medium text-[16px] "
              >
                <>{t("login")}</>
              </button>
            )}
          </div>

          <div className="text-[#ABABAB] font-Glacial Indifference text-[16px] flex items-center justify-center  mt-3">
            {t("homePage.dontHave")}
            <span
              onClick={handleSignupModalOpen}
              className="cursor-pointer text-primary"
            >
              &nbsp;{t("signUp")}
            </span>
          </div>
        </form>
      </div>
    </>
  );
};

export default LogIn;
