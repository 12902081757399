import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUnread: false,
};

const unreadSlice = createSlice({
  name: "isUnread",
  initialState,
  reducers: {
    setIsUnread: (state, action) => {
      state.isUnread = action.payload;
    },
  },
});

export const { setIsUnread } = unreadSlice.actions;

export default unreadSlice.reducer;
