import React from "react";

const DishCategory = ({ food, handleClick, isSelected }) => {
  const lang = localStorage.getItem("i18nextLng");
  return (
    <div onClick={() => handleClick(food._id)}>
      <div
        className={`food my-5 mx-0 w-[153px] h-[200px] rounded-[8px] shadow-md  shadow-[#D6D6D6E5] flex flex-col justify-between relative cursor-pointer ${
          isSelected ? "border-primary border-[3px]" : ""
        }`}
      >
        <div className="h-[50px] w-[40px] bg-[#FF914D1A] self-center absolute "></div>
        <div className="py-4 px-4 z-10">
          <img
            className="bg-[black] h-[130px] rounded-lg object-cover"
            src={food.imageUri}
            alt="dishcategory"
          />
        </div>
        <p className="w-[153px] bg-[#FF914D1A] text-center top-0 py-[6px] font-Glacial cursor-pointer">
          {lang === "en" || lang === "en-US" ? (
            <div className=" ">{food?.translations?.en?.foodType}</div>
          ) : (
            <div className=" ">{food?.foodType}</div>
          )}
        </p>
      </div>
    </div>
  );
};

export default DishCategory;
