import React from "react";
import Header from "../components/Header";
import Title from "../components/Title";
import Review from "../components/Review";
import { useTranslation } from "react-i18next";
import ShowCookies from "../components/ShowCookies";

const ShowReviews = () => {
  const { t } = useTranslation();
  
  return (
    <div className="container mx-auto w-full">
       <ShowCookies />
      <div>
        <Header />
      </div>
      <div>
        <Title title={t("profilePage.showReviews")} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 my-4">
        {[...Array(16).keys()]?.map((item, index) => {
          return (
            <>
              <Review />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ShowReviews;
