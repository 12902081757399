import React from "react";

const User = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="20"
        viewBox="0 0 17 20"
        fill="none"
      >
        <path
          d="M8.19961 8.2C10.1878 8.2 11.7996 6.58822 11.7996 4.6C11.7996 2.61177 10.1878 1 8.19961 1C6.21138 1 4.59961 2.61177 4.59961 4.6C4.59961 6.58822 6.21138 8.2 8.19961 8.2Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
        <path
          d="M15.4 14.9499C15.4 17.1867 15.4 18.9999 8.2 18.9999C1 18.9999 1 17.1867 1 14.9499C1 12.7131 4.22355 10.8999 8.2 10.8999C12.1765 10.8999 15.4 12.7131 15.4 14.9499Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
      </svg>
    </>
  );
};

export default User;
