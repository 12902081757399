import React from "react";

const DeleteIcon = () => {
  return (
    <div>
      <svg
        width="15"
        height="18"
        viewBox="0 0 15 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.94118 4.76471V15.1176C1.94118 16.1573 2.78393 17 3.82353 17H11.3529C12.3926 17 13.2353 16.1573 13.2353 15.1176V4.76471M1.94118 4.76471H1M1.94118 4.76471H3.82353M13.2353 4.76471H14.1765M13.2353 4.76471H11.3529M3.82353 4.76471V2.88235C3.82353 1.84276 4.66629 1 5.70588 1H9.47059C10.5102 1 11.3529 1.84276 11.3529 2.88235V4.76471M3.82353 4.76471H11.3529M5.70588 8.52941V13.2353M9.47059 8.52941V13.2353"
          stroke="#FF914D"
          stroke-width="0.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default DeleteIcon;
