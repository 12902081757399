import React, { useState } from "react";
import PlusSVG from "../svg/PlusSVG";
import MinusSVG from "../svg/MinusSVG";

const AccordionItem = ({ title, content, titleEn, contentEn }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const user = localStorage.getItem("root");
  const lang = localStorage.getItem("i18nextLng");
  const authData = JSON.parse(user).auth;
  const token = JSON.parse(authData).token;

  return (
    <div className="w-full border-[1px] border-[#FF914D] rounded-lg  mb-[5px]">
      <div
        className="w-full   mx-auto flex justify-between items-center p-4 cursor-pointer"
        onClick={toggleAccordion}
      >
        {lang === "en" || lang === "en-US" ? (
          <div className="font-semibold text-[18px] ">{titleEn}</div>
        ) : (
          <div className="font-semibold text-[18px] ">{title}</div>
        )}
        {isOpen ? (
          <>
            <MinusSVG />
          </>
        ) : (
          <>
            <PlusSVG />
          </>
        )}
        {/* <div
          className={`transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          <img src="../images/Plus.svg" alt="" />
        </div> */}
      </div>
      {isOpen && (
        <div className="p-4">
          {lang === "en" || lang === "en-US" ? (
            <p className="text-[16px]">{contentEn}</p>
          ) : (
            <p className="text-[16px]">{content}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
