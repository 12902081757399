import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startDate: new Date(),
  endDate: new Date(),
  noOfDays: 0,
  noOfPeople: 0,
};

const noOfDaysSlice = createSlice({
  name: "noOfDays",
  initialState,
  reducers: {
    setNoOfDays: (state, action) => {
      const { startDate, endDate, noOfDays, noOfPeople } = action.payload;
      state.startDate = startDate;
      state.endDate = endDate;
      state.noOfDays = noOfDays;
      state.noOfPeople = noOfPeople;
    },
  },
});

export const { setNoOfDays } = noOfDaysSlice.actions;

export default noOfDaysSlice.reducer;
