import React from "react";

const StepsCard = ({ title, svgIcon }) => {
  return (
    <div>
      <div className="text-[18px] font-Comfortaa font-bold">{title}</div>
      <div className="mt-6 border p-10 rounded-[20px]">
        <img className="w-[240px] h-[240px]" src={svgIcon} alt={title} />
      </div>
    </div>
  );
};

export default StepsCard;
