import api from "./Api";

export function gethostTOC() {
  return api.get("/terms-condition-host");
}

export function getguestTOC() {
  return api.get("/terms-condition-guest");
}

export function getLegalNotice() {
  return api.get("/legal-notice");
}

export function getPrivacyPolicy() {
  return api.get("/privacy-policy");
}
export function getCookiesPolicy() {
  return api.get("/cookies-policy");
}
export function getAffidavit() {
  return api.get("/affidavit");
}
