import React from "react";

const HowIt3 = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29 16C29 18.5712 28.2376 21.0846 26.8091 23.2224C25.3806 25.3603 23.3503 27.0265 20.9749 28.0104C18.5994 28.9944 15.9856 29.2518 13.4638 28.7502C10.9421 28.2486 8.62569 27.0105 6.80761 25.1924C4.98953 23.3743 3.7514 21.0579 3.24979 18.5362C2.74818 16.0144 3.00563 13.4006 3.98957 11.0251C4.97351 8.64968 6.63975 6.61935 8.77759 5.1909C10.9154 3.76244 13.4288 3 16 3V16H29Z"
          fill="#FF914D"
        />
        <circle cx="16" cy="16" r="15.5" stroke="#FF914D" />
      </svg>
    </div>
  );
};

export default HowIt3;
