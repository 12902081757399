import React from "react";

const FoodCategory = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        className="mt-[5px]"
      >
        <path
          d="M10 3.125C7.61305 3.125 5.32388 4.0732 3.63605 5.76103C1.94823 7.44886 1 9.73805 1 12.125H19C19 9.73805 18.0518 7.44886 16.364 5.76103C14.6761 4.0732 12.3869 3.125 10 3.125Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 15.375H19"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 1V3.12512"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default FoodCategory;
