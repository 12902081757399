import api from "./Api";

// sign-in
export const signIn = (data) => {
  return api.post("/user-logIn", data);
};

// register user
export const registerUser = (data) => {
  return api.post("/register-user", data);
};

// verify otp
export const verifyOtp = (data) => {
  return api.post("/verify-otp", data);
};

// forgot password
export const forgotPassword = (data) => {
  return api.put("/forgot-password", data);
};

// reset password
export const resetPassword = (data) => {
  return api.put("/password", data);
};

// Profile Edit
export const profileEdit = (data) => {
  return api.put("/user-profile", data);
};

// contact
export const contactUs = (data) => {
  return api.post("/contact", data);
};

export const verificationImages = (data) => {
  return api.post("/verification-images", data);
}