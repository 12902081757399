import api from "./Api";

export const uploadSingleImage = (data) => {
  return api.post("/image", data);
};

export const getFoodTypes = () => {
  return api.get("/food-categories");
};

export const getMealTimes = () => {
  return api.get("/meal-timings");
};

export const getFavoriteDishes = (id) => {
  return api.get(`/guest/favorites?id=${id}`);
};

export const addToFavorite = (id) => {
  return api.post(`/guest/favorites?id=${id}`);
};

export const removeFromFavorite = (id) => {
  return api.delete(`/guest/favorite?id=${id}`);
};
export const editHostListing = (data) => {
  return api.post("/host/listing",data);
};
export const updateHostListing = (data) => {
  return api.post("/host/listing",data);
};
export const getFoodCategory = () => {
  return api.get("/food-categories");
};
export const getFoodTime = () => {
  return api.get("/meal-timings");
};
export const getCancellation = () => {
  return api.get("/cancellation-policies");
};

export const getSocialLinks = () => {
  return api.get("/social-link");
}