import React from "react";
import Header from "../components/Header";
import Title from "../components/Title";
import InputField from "../components/InputField";
import DBtn from "../components/DBtn";
import User from "../svg/User";
import Location from "../svg/Location";
import Bank from "../svg/Bank";
import Iban from "../svg/Iban";

const BankDetails = () => {
  return (
    <div>
      <div>
        <div className="border-b-[1px] border-[#FF914D]">
          <div className="container mx-auto w-full">
            <div className="">
              <Header />
            </div>
          </div>
        </div>
        <div className="m-auto md:container pt-[40px] font-bold">
          <Title title="Add bank details" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-4 container mx-auto pt-[40px]  ">
          <div className="w-[396px]">
            <InputField
              startIcon={<User />}
              placeholder="Enter account holder name"
            />
          </div>
          <div className="w-[396px]">
            <InputField
              startIcon={<Bank />}
              placeholder="Enter bank account number"
            />
          </div>
          <div className="w-[396px]">
            <InputField startIcon={<Location />} placeholder="Enter country" />
          </div>
          <div className="w-[396px]">
            <InputField startIcon="***" placeholder="Enter SWIFT code" />
          </div>
          <div className="w-[396px]">
            <InputField startIcon={<Bank />} placeholder="Enter bank name" />
          </div>
          <div className="w-[396px]">
            <InputField startIcon={<Iban />} placeholder="Enter IBAN" />
          </div>
        </div>
      </div>
      <div className="sm:container mx-auto md:pt-[250px]">
        <div className=" sm:container mx-auto mb-[70px] pt-[32px] flex justify-end">
          <DBtn
            variant="contain"
            size=""
            onClick={(e) => console.log("button click")}
            startIcon=""
          >
            Save
          </DBtn>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
