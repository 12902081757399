import React from "react";
import Header from "../components/Header";
import Title from "../components/Title";
import Profile from "../components/Profile";
import RadioGroup from "../components/TranslationRadio";
import InputField from "../components/InputField";
import EditAddressCard from "../components/EditAddressCard";
import DBtn from "../components/DBtn";
import Logout from "../svg/Logout";
import Star from "../svg/Star";
import ContactUs from "../svg/ContactUs";
import Cake from "../svg/Cake";
import Email from "../svg/Email";
import Bank from "../svg/Bank";
import Host from "../svg/Host";
import DownArrow from "../svg/DownArrow";

const HostProfilePage = () => {
  return (
    <div className="container mx-auto w-full">
      <div>
        <Header />
      </div>
      <div className="main">
        <div className="flex justify-between">
          <div className="left p-[60px] border-r-2 border-[#FF914D]">
            <div className="m-auto container">
              <Title title="Profile" />
              <Profile />
              <div className="pt-[150px]">
                <div className="pt-[30px] w-full flex">
                  <DBtn
                    variant="outline"
                    size="small"
                    onClick={(e) => console.log("button click")}
                    startIcon={<Logout />}
                  >
                    Logout
                  </DBtn>
                </div>
              </div>
            </div>
          </div>

          <div className="center p-[60px] border-r-2 border-[#FF914D] ">
            <div className="flex items-center">
              <div>
                <Title title="Personal information" />
              </div>
              <div className="pt-[20px] pb-[15px] pl-[15px] flex justify-end">
                <DBtn
                  variant="contain"
                  size="small"
                  onClick={(e) => console.log("button click")}
                  startIcon=""
                >
                  Availability
                </DBtn>
              </div>
            </div>
            <div className="pt-[44px]">
              <EditAddressCard
                title="First Name"
                placeholder1="Spanish"
                placeholder2="English"
              />
            </div>
            <div className="pt-[33px]">
              <EditAddressCard
                title="Last Name"
                placeholder1="Spanish"
                placeholder2="English"
              />
            </div>
            <div className="pt-[33px]">
              <InputField
                startIcon={<Cake />}
                placeholder="Enter Your Birthdate"
              />
            </div>
            <div className="pt-[33px]">
              <InputField
                startIcon={<Email />}
                placeholder="Enter Your Email"
              />
            </div>
            <div className="pt-[35px]">
              <EditAddressCard
                title="Bio Detail"
                placeholder1="Spanish"
                placeholder2="English"
              />
            </div>
          </div>

          <div className="right p-[60px] mt-[93px]">
            <div className="pt-[35px]">
              <EditAddressCard
                title="Address"
                placeholder1="Spanish"
                placeholder2="English"
              />
            </div>

            <div className="pt-[24px]">
              <Title title="Add bank details" />
            </div>
            <div className="pt-[33px]">
              <InputField
                startIcon={<Bank />}
                placeholder="Add bank details here"
              />
            </div>
            <div className="flex justify-between pt-[45px]">
              <div>
                <Title title="Login & Security" />
              </div>
              <div className="flex justify-center items-center">
                <DBtn
                  variant="outline"
                  size="Medium"
                  onClick={(e) => console.log("button click")}
                  startIcon={<Star />}
                >
                  <span className="pt-[3px]">3.5</span>
                </DBtn>
              </div>
            </div>
            <div>
              <DBtn
                variant="contain"
                size="Medium"
                onClick={(e) => console.log("button click")}
                startIcon=""
              >
                Change password
              </DBtn>
            </div>
            <div className="pt-[24px]">
              <Title title="Translation" />
            </div>
            <div className="pt-[16px]">
              <RadioGroup />
            </div>
            <div className="pt-[24px]">
              <Title title="Data privacy" />
            </div>
            <div className="w-[536px] font-Glacial pt-[16px]">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using.
              </p>
            </div>
            <div className="pt-[24px]">
              <Title title="Switch profile" />
            </div>
            <div className="pt-[33px]">
              <InputField
                startIcon={<Host />}
                placeholder="Host"
                endIcon={<DownArrow />}
              />
            </div>

            <div className="flex pt-[20px]">
              <div className="pr-[24px] pt-[25px]">
                <DBtn
                  variant="outline"
                  size="small"
                  onClick={(e) => console.log("button click")}
                  startIcon={<ContactUs />}
                >
                  Contact Us
                </DBtn>
              </div>
              <div className="pt-[25px]">
                <DBtn
                  variant="contain"
                  size="small"
                  onClick={(e) => console.log("button click")}
                  startIcon=""
                >
                  Delete Account
                </DBtn>
              </div>
            </div>
            <div className="pt-[30px]">
              <div className="pl-[5px]">
                <DBtn
                  variant="contain"
                  size="xlarge"
                  onClick={(e) => console.log("button click")}
                  startIcon=""
                >
                  Save
                </DBtn>
              </div>
              <div className="pt-[24px]">
                <DBtn
                  variant="outline"
                  size="xlarge"
                  onClick={(e) => console.log("button click")}
                  startIcon=""
                >
                  Cancel
                </DBtn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[480px] container mx-auto">
        <div className="font-bold font-Comfortaa text-2xl pt-[34px]">
          <h2>Add address details</h2>
        </div>
        <div className="pb-[34px] pt-[34px]">
          <InputField />
        </div>
        <div className="pb-[34px]">
          <InputField />
        </div>
        <div className="pb-[34px]">
          <InputField />
        </div>
        <div className="pb-[34px]">
          <InputField />
        </div>
        <div className="pb-[34px]">
          <InputField />
        </div>
        <div className="pb-[34px]">
          <InputField />
        </div>
        <div>
          <DBtn
            variant="contain"
            size="xlarge"
            onClick={(e) => console.log("button click")}
            startIcon=""
          >
            Save
          </DBtn>
        </div>
      </div>
    </div>
  );
};

export default HostProfilePage;
