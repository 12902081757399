import React from "react";

const EmptyStar = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="25"
        viewBox="0 0 24 23"
        fill="none"
        className="pr-[6px]"
      >
        <path
          d="M11.8098 1.58541C11.8697 1.40115 12.1303 1.40115 12.1902 1.58541L14.6493 9.1536C14.676 9.236 14.7528 9.2918 14.8395 9.2918H22.7971C22.9909 9.2918 23.0714 9.53972 22.9147 9.6536L16.4768 14.331C16.4067 14.3819 16.3774 14.4722 16.4042 14.5546L18.8632 22.1228C18.9231 22.3071 18.7122 22.4603 18.5554 22.3464L12.1176 17.669C12.0475 17.6181 11.9525 17.6181 11.8824 17.669L5.44456 22.3464C5.28781 22.4603 5.07692 22.3071 5.13679 22.1228L7.59584 14.5546C7.62262 14.4722 7.59329 14.3819 7.52319 14.331L1.0853 9.6536C0.928559 9.53972 1.00911 9.2918 1.20286 9.2918H9.16052C9.24717 9.2918 9.32396 9.236 9.35073 9.1536L11.8098 1.58541Z"
          stroke="#FF914D"
        />
      </svg>
    </div>
  );
};

export default EmptyStar;
