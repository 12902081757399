import React from "react";
import { useTranslation } from "react-i18next";
import { formatDateToMMMYYYY } from "../utils/CommonFunctions";

const HostCard = ({
  hostname,
  hostsurname,
  hostDate,
  dishDiscription,
  dishDiscriptionEn,
  reviews,
}) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  return (
    <>
      <div className="w-[360px] mt-3">
        <p className="text-lg text-[#111111] font-Glacial capitalize">
          {t("dishDetail.hostedBy")} {hostname} {hostsurname}
        </p>
        <p className="font-Glacial text-md text-[#ABABAB] mt-2">
          {t("dishDetail.joinIn")} {formatDateToMMMYYYY(hostDate)}
        </p>
        <div className="flex gap-x-3 items-center sm:text-[16px] text-[12px] text-[#474747] font-Glacial pt-2">
          <div className="flex items-center gap-x-1">
            <img src="../images/Hostecard/review.svg" alt="" />
            <p className="text-md">
              {reviews} {t("dishDetail.reviews")}
            </p>
          </div>
          <div className="flex items-center gap-x-1">
            <img src="../images/Hostecard/verified.svg" alt="" />
            <p className="text-md">{t("dishDetail.identityVerify")}</p>
          </div>
          <div className="flex items-center gap-x-1">
            <img src="../images/Hostecard/superhost.svg" alt="" />
            <p className="text-md">{t("dishDetail.superHost")}</p>
          </div>
        </div>
        <p className="text-[16px] font-Glacial text-[#474747] my-4  ">
          {lang === "en" || lang === "en-US" ? (
            <div className=" ">{dishDiscriptionEn}</div>
          ) : (
            <div className=" ">{dishDiscription}</div>
          )}
        </p>
        {/* <div className="text-[16px] font-Glacial text-[#ABABAB] ">
          <p className="pt-[10px]">{t("dishDetail.regNo")} : 123456789SAGAR </p>
          <p className="py-[5px]">{t("dishDetail.language")} : English </p>
          <p className="py-[5px]">{t("dishDetail.responseRate")} : 100% </p>
          <p className="pt-[5px]">
            {t("dishDetail.responseTime")} : within an hour
          </p>
        </div> */}
      </div>
    </>
  );
};

export default HostCard;
