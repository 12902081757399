import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import Header from "../components/Header";
import Title from "../components/Title";
import Modal from "../components/Modal";
import CardAvailable from "../components/CardAvailable";
import Loader from "../components/Loader";
import PlusIcon from "../svg/PlusIcon";
import DeleteIcon from "../svg/DeleteIcon";
import { getAvailability, postAvailability } from "../services/profileService";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const AvailableList = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);

  const [dataAvailable, setDataAvailable] = useState([]);
  const [initDate, setInitDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [timeRanges, setTimeRanges] = useState([]);
  // console.log("dataAvailable", dataAvailable);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddTime = () => {
    setTimeRanges([
      ...timeRanges,
      { startTime: "9:00 am", endTime: "10:00 am" },
    ]);
  };

  const handleDeleteTime = (index) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges.splice(index, 1);
    setTimeRanges(updatedTimeRanges);
  };

  const handleStartTimeChange = (value, index) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges[index].startTime = value;
    setTimeRanges(updatedTimeRanges);
  };

  const handleEndTimeChange = (value, index) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges[index].endTime = value;
    setTimeRanges(updatedTimeRanges);
  };

  const timeOptions = [];
  for (let hour = 9; hour <= 12; hour++) {
    timeOptions.push(`${hour}:00 am`);
    timeOptions.push(`${hour}:30 am`);
  }

  for (let hour = 1; hour <= 9; hour++) {
    timeOptions.push(`${hour}:00 pm`);
    timeOptions.push(`${hour}:30 pm`);
  }

  const getHostAvaliability = async () => {
    try {
      setIsLoading(true);
      const resp = await getAvailability();
      // console.log("resp", resp.data);
      const outData = resp?.data?.map((item) => {
        return {
          userId: item?.userId,
          startDate: item?.startDate,
          endDate: item?.endDate,
          timeSlots: item?.timeSlots,
        };
      });

      setDataAvailable(outData);
      // setDataAvailable(resp?.data);
    } catch (error) {
      console.log("getHostAvaliability: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const addHostAvailablity = async () => {
    const payload = {
      dates: [
        {
          userId: user?.id,
          startDate: initDate[0]?.startDate,
          endDate: initDate[0]?.endDate,
          timeSlots: timeRanges.map((timeRange) => ({
            startTime: timeRange.startTime,
            endTime: timeRange.endTime,
          })),
        },
      ],
    };

    console.log(" payload :::", payload);
    try {
      setLoading(true);
      const resp = await postAvailability(payload);
      if (resp?.success) {
        setIsModalOpen(false);
      }
    } catch (error) {
      console.log("AddHostAvailability: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (timeRanges.length > 0) {
      addHostAvailablity();
      setFlag(true);
    }
  };

  useEffect(() => {
    getHostAvaliability();
    setFlag(false);
  }, [flag]);

  // console.log("dataAvailable", dataAvailable);
  return (
    <div>
      <Header />
      {isLoading ? (
        <div className="flex justify-center items-center m-auto h-[50vh]">
          <Loader />
        </div>
      ) : (
        <>
          <div className="border-b-[1px] border-[#FF914D]"></div>

          <div className="container mt-5 mx-auto w-full">
            <div className="flex">
              <Title title={t("avalability.header")} />
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="bg-white border border-primary text-primary hover:bg-primary hover:text-white w-[230px] h-[54px] px-4 rounded-full mx-auto  font-Comfortaa-Medium text-[16px]"
              >
                {t("avalability.btn")}
              </button>
            </div>
            {isModalOpen && (
              <Modal
                width={1050}
                height={600}
                maxHeight={800}
                // scrollbarCheck={true}
                onClose={() => setIsModalOpen(false)}
              >
                <div className="ml-10">
                  <Title title={t("avalability.addAvailable")} />
                </div>
                <div className="flex mx-10 justify-between">
                  <div className="mt-10  text-[18px] font-Glacial">
                    <h1>{t("avalability.chooseDate")}</h1>
                    <div className="flex gap-10 mt-5">
                      <div className="flex border border-primary rounded-full">
                        <div className=" py-2 px-5">
                          {t("avalability.from")}
                        </div>
                        <div className="border-l border-primary rounded-full py-2 px-8">
                          {format(initDate[0].startDate, "dd-MM-yyyy")}
                        </div>
                      </div>
                      <div className="flex border border-primary rounded-full">
                        <div className=" py-2 px-5">{t("avalability.to")}</div>
                        <div className="border-l border-primary rounded-full py-2 px-8">
                          {format(initDate[0].endDate, "dd-MM-yyyy")}
                        </div>
                      </div>
                    </div>
                    <div className="mt-10">
                      <h1 className="flex items-center justify-between">
                        {t("avalability.chooseTime")}
                        <div className="cursor-pointer" onClick={handleAddTime}>
                          <PlusIcon />
                        </div>
                      </h1>
                      <div className="h-[300px] overflow-y-auto max-h-[500px]">
                        {timeRanges.length === 0 ? (
                          <div className="text-red-300">
                            {t("avalability.pleaseAdd")}
                          </div>
                        ) : (
                          timeRanges.map((timeRange, index) => (
                            <div
                              key={index}
                              className="flex items-center justify-between mt-5"
                            >
                              <div className="flex items-center gap-5">
                                <select
                                  placeholder="Start Time"
                                  value={timeRange?.startTime}
                                  onChange={(e) =>
                                    handleStartTimeChange(e.target.value, index)
                                  }
                                  className="focus:outline-none w-[140px] border px-4 py-2 border-primary rounded-full"
                                >
                                  {timeOptions.map((time, optionIndex) => (
                                    <option key={optionIndex} value={time}>
                                      {time}
                                    </option>
                                  ))}
                                </select>
                                -
                                <select
                                  placeholder="End Time"
                                  value={timeRange?.endTime}
                                  onChange={(e) =>
                                    handleEndTimeChange(e.target.value, index)
                                  }
                                  className="focus:outline-none w-[140px] border px-4 py-2 border-primary rounded-full"
                                >
                                  {timeOptions.map((time, optionIndex) => (
                                    <option key={optionIndex} value={time}>
                                      {time}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="flex items-center gap-8">
                                <div
                                  className="cursor-pointer"
                                  onClick={() => handleDeleteTime(index)}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-10 flex gap-5 h-[380px]">
                    <DateRange
                      className="border p-4 rounded-md shadow-md"
                      editableDateInputs={true}
                      onChange={(item) => setInitDate([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={initDate}
                      rangeColors={["#FF914D"]}
                    />
                  </div>
                </div>
                {isLoading ? (
                  <div className=" border-primary bg-white text-[#FFF]  border-[3px] text-xs rounded-full w-[100px] h-[35px] px-4 py-2 my-6">
                    <Loader size={17} />
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="bg-primary hover:bg-white text-white hover:text-primary hover:border hover:border-primary w-[130px] h-[54px] px-4 rounded-full mx-auto  font-Comfortaa-Medium text-[16px] absolute bottom-10 right-14"
                  >
                    {t("addNewDish.save")}
                  </button>
                )}
              </Modal>
            )}

            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 my-10">
              {Array.isArray(dataAvailable) &&
                dataAvailable.map((item, index) => (
                  <CardAvailable
                    key={index}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    timeSlots={item.timeSlots.map((slot) => ({
                      startTime: slot?.startTime,
                      endTime: slot?.endTime,
                    }))}
                  />
                ))}
              {/* {Array.isArray(dataAvailable) &&
                dataAvailable.map((item, index) => (
                  <div
                    key={index}
                    className="relative w-[450px] h-[170px] border border-primary rounded-lg pr-16 p-4 font-Glacial"
                  >
                    <div className="flex justify-between">
                      <h1 className="text-[18px] text-[#474747] flex">
                        <span className="text-primary">From&nbsp;</span>
                        <span className="text-primary">:</span>&nbsp;
                        {formatDate(item.startDate)}
                      </h1>
                      <h1 className="text-[18px] text-[#474747]">
                        <span className="text-primary">To&nbsp;</span>
                        <span className="text-primary">:</span>&nbsp;
                        {formatDate(item.endDate)}
                      </h1>
                    </div>
                    <div className="max-h-[400px] h-[120px] overflow-y-auto ">
                      {item.timeSlots.map((slot, slotIndex) => (
                        <div
                          key={slotIndex}
                          className="flex items-center gap-4 mt-3"
                        >
                          <div className="focus:outline-none w-[100px] border px-4 py-2 border-primary rounded-full">
                            {slot.startTime}
                          </div>
                          -
                          <div className="focus:outline-none w-[100px] border px-4 py-2 border-primary rounded-full">
                            {slot.endTime}
                          </div>
                        </div>
                      ))}
                      <div
                        className="absolute right-5 bottom-5"
                        onClick={() => setIsModalOpenUpdate(true)}
                      >
                        <Edit />
                      </div>
                      {isModalOpenUpdate && (
                        <Modal
                          onClose={() => setIsModalOpenUpdate(false)}
                          width={1050}
                          height={600}
                          maxHeight={800}
                        >
                          <div className="ml-10">
                            <Title title="Update availability" />
                          </div>
                          <div className="flex mx-10 justify-between">
                            <div className="mt-10  text-[18px] font-Glacial">
                              <h1>Choose date</h1>
                              <div className="flex gap-10 mt-5">
                                <div className="flex border border-primary rounded-full">
                                  <div className=" py-2 px-5">From</div>
                                  <div className="border-l border-primary rounded-full py-2 px-8">
                                    {formatDate(item?.startDate)}
                                  </div>
                                </div>
                                <div className="flex border border-primary rounded-full">
                                  <div className=" py-2 px-5">To</div>
                                  <div className="border-l border-primary rounded-full py-2 px-8">
                                    {formatDate(item?.endDate)}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-10">
                                <h1 className="flex items-center justify-between">
                                  Choose time
                                  <div
                                    className="cursor-pointer"
                                    onClick={handleAddTime}
                                  >
                                    <PlusIcon />
                                  </div>
                                </h1>
                                <div className="h-[300px] max-h-[500px] overflow-y-auto">
                                  {item?.timeSlots.map((slot, index) => (
                                    <div
                                      key={index}
                                      className="flex items-center justify-between gap-4 mt-3"
                                    >
                                      <div className="flex items-center gap-5">
                                        <select
                                          placeholder="Start Time"
                                          value={slot?.startTime}
                                          onChange={(e) =>
                                            handleStartTimeChange(
                                              e.target.value,
                                              index
                                            )
                                          }
                                          className="focus:outline-none w-[140px] border px-4 py-2 border-primary rounded-full"
                                        >
                                          {timeOptions.map(
                                            (time, optionIndex) => (
                                              <option
                                                key={optionIndex}
                                                value={time}
                                              >
                                                {time}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        -
                                        <select
                                          placeholder="End Time"
                                          value={slot?.endTime}
                                          onChange={(e) =>
                                            handleEndTimeChange(
                                              e.target.value,
                                              index
                                            )
                                          }
                                          className="focus:outline-none w-[140px] border px-4 py-2 border-primary rounded-full"
                                        >
                                          {timeOptions.map(
                                            (time, optionIndex) => (
                                              <option
                                                key={optionIndex}
                                                value={time}
                                              >
                                                {time}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      <div className="flex items-center gap-8">
                                        <div
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleDeleteTime(index)
                                          }
                                        >
                                          <DeleteIcon />
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="mt-10 flex gap-5 h-[380px]">
                              <DateRange
                                className="border p-4 rounded-md shadow-md"
                                editableDateInputs={true}
                                onChange={(it) =>
                                  setInitDate([it?.selection])
                                }
                                moveRangeOnFirstSelection={false}
                                ranges={initDate}
                                rangeColors={["#FF914D"]}
                              />
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={handleUpdate}
                            className="bg-primary hover:bg-white text-white hover:text-primary hover:border hover:border-primary w-[130px] h-[54px] px-4 rounded-full mx-auto  font-Comfortaa-Medium text-[16px] absolute bottom-10 right-14"
                          >
                            Update
                          </button>
                        </Modal>
                      )}
                    </div>
                  </div>
                ))} */}
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default AvailableList;
