import React, { useEffect } from "react";
import { useFileUpload } from "use-file-upload";
import { PiImageSquareBold } from "react-icons/pi";
import { useTranslation } from "react-i18next";

const Profile = ({
  image = "https://www.pngkit.com/png/full/301-3012694_account-user-profile-avatar-comments-fa-user-circle.png",
  onChange,
}) => {
  const [files, selectFiles] = useFileUpload();
  const { t } = useTranslation();
  useEffect(() => {
    onChange(files?.file);
  }, [files]);
  return (
    <div
      id="Profile"
      className="container w-[188px]  flex justify-center items-center flex-col my-10 mx-16 lg:mx-0"
    >
      <img
        src={files?.source || image}
        alt="preview"
        className="rounded-full w-[200px] h-[200px]"
      />
      {/* <button
        className="mt-5 border-[1px] border-[#FF914D] py-2 px-3 rounded-full flex items-center gap-x-2 text-[16px] text-[#111111] font-Glacial"
        onClick={() => {
          selectFiles({ accept: "image/*" }, ({ name, size, source, file }) => {
            console.log("Files Selected", { name, size, source, file });
          });
        }}
      >
        <PiImageSquareBold className="text-primary text-[16px]" />{" "}
        {t("profilePage.uploadImageLabel")}
      </button> */}
    </div>
  );
};

export default Profile;
