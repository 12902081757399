import React from "react";

const Become1 = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 24C8.51429 24 5.02857 24 1.48571 24C0.514286 24 0 23.4857 0 22.5143C0 18.9143 0 15.3143 0 11.7143C0 10.8 0.514286 10.2857 1.42857 10.2857C8.45714 10.2857 15.4857 10.2857 22.5143 10.2857C23.4857 10.2857 23.9429 10.8 23.9429 11.7714C23.9429 15.3714 23.9429 18.9714 23.9429 22.5143C23.9429 23.4857 23.4286 24 22.4571 24C19.0286 24 15.4857 24 12 24ZM12 23.1428C15.4857 23.1428 19.0286 23.1428 22.5143 23.1428C23.0286 23.1428 23.1429 23.0286 23.1429 22.5143C23.1429 18.9143 23.1429 15.3143 23.1429 11.7714C23.1429 11.2571 23.0286 11.1428 22.5143 11.1428C15.4857 11.1428 8.45714 11.1428 1.48571 11.1428C0.971428 11.1428 0.857143 11.2571 0.857143 11.7714C0.857143 15.3714 0.857143 18.9143 0.857143 22.5143C0.857143 23.0286 0.971428 23.1428 1.48571 23.1428C5.02857 23.1428 8.51429 23.1428 12 23.1428Z"
          fill="#FF914D"
        />
        <path
          d="M12 8.97143C9.54286 8.97143 7.48572 6.97143 7.48572 4.51429C7.48572 2 9.48572 0 11.9429 0C14.4571 0 16.4571 2 16.4571 4.51429C16.5143 6.97143 14.4571 8.97143 12 8.97143ZM15.6571 4.51429C15.6571 2.51429 14.0571 0.857143 12.0571 0.857143C10.0571 0.857143 8.4 2.45714 8.4 4.45714C8.4 6.45714 10 8.11429 12 8.17143C14 8.17143 15.6571 6.51429 15.6571 4.51429Z"
          fill="#FF914D"
        />
        <path
          d="M17.3714 17.1429C17.3714 20.1143 14.9143 22.5143 11.9429 22.4572C8.97144 22.4572 6.62858 20 6.62858 17.0286C6.62858 14.1143 9.08572 11.7143 12.0571 11.7143C14.9714 11.8286 17.3714 14.2286 17.3714 17.1429ZM12.0571 12.6286C9.54286 12.6286 7.54286 14.6286 7.54286 17.1429C7.54286 19.6 9.54286 21.6 12.0571 21.6572C14.5143 21.6572 16.5714 19.6572 16.5714 17.2C16.5143 14.6857 14.5143 12.6286 12.0571 12.6286Z"
          fill="#FF914D"
        />
        <path
          d="M2.57143 12.8571C2.57143 13.4857 2.57143 14.1143 2.57143 14.7429C2.57143 14.8571 2.57143 14.9143 2.57143 15.0286C2.57143 15.2571 2.4 15.4286 2.17143 15.4286C1.88572 15.4286 1.71429 15.2571 1.71429 14.9714C1.71429 14.1143 1.71429 13.2571 1.71429 12.4C1.71429 12.1714 1.88572 12 2.11429 12C2.97143 12 3.82857 12 4.68572 12C4.97143 12 5.14286 12.1714 5.14286 12.4C5.14286 12.6286 4.97143 12.8 4.68572 12.8571C4.11429 12.8571 3.48572 12.8571 2.91429 12.8571C2.8 12.8571 2.68572 12.8571 2.57143 12.8571Z"
          fill="#FF914D"
        />
        <path
          d="M21.4286 12.8571C20.7429 12.8571 20.0571 12.8571 19.4286 12.8571C19.0857 12.8571 18.8571 12.6857 18.8571 12.4C18.8571 12.1143 19.0857 12 19.4286 12C20.2286 12 20.9714 12 21.7714 12C22.1143 12 22.2857 12.1714 22.2857 12.5143C22.2857 13.3143 22.2857 14.1143 22.2857 14.9143C22.2857 15.2571 22.1143 15.4286 21.8286 15.4286C21.5429 15.4286 21.4286 15.2 21.4286 14.9143C21.4286 14.2286 21.4286 13.5429 21.4286 12.8571Z"
          fill="#FF914D"
        />
        <path
          d="M2.57143 21.4286C3.25714 21.4286 3.88572 21.4286 4.51429 21.4286C4.62857 21.4286 4.68572 21.4286 4.8 21.4286C4.97143 21.4857 5.14286 21.6 5.14286 21.8286C5.14286 22.1143 5.02857 22.2857 4.8 22.2857C3.88572 22.2857 2.97143 22.2857 2.11429 22.2857C1.82857 22.2857 1.71429 22.0571 1.71429 21.8286C1.71429 20.9714 1.71429 20.1714 1.71429 19.3143C1.71429 19.0286 1.88572 18.8571 2.11429 18.8571C2.34286 18.8571 2.57143 19.0286 2.57143 19.3143C2.57143 20 2.57143 20.6857 2.57143 21.4286Z"
          fill="#FF914D"
        />
        <path
          d="M21.4286 21.4286C21.4286 20.8 21.4286 20.1714 21.4286 19.5428C21.4286 19.4286 21.4286 19.3714 21.4286 19.2571C21.4286 19.0286 21.6 18.8 21.8286 18.8C22.0571 18.8 22.2286 18.9714 22.2857 19.2C22.2857 20.0571 22.2857 20.9143 22.2857 21.7714C22.2857 22.0571 22.1143 22.2286 21.8286 22.2286C20.9714 22.2286 20.1143 22.2286 19.3143 22.2286C19.0857 22.2286 18.8571 22.0571 18.8571 21.8286C18.8571 21.6 19.0286 21.3714 19.3143 21.3714C20 21.4286 20.6857 21.4286 21.4286 21.4286Z"
          fill="#FF914D"
        />
        <path
          d="M11.6 4.9143C11.1428 4.9143 10.6857 4.9143 10.2286 4.9143C10.1143 4.9143 9.94285 4.9143 9.82856 4.80001C9.71428 4.68573 9.65714 4.5143 9.65714 4.34287C9.65714 4.17144 9.82856 4.05716 10.0571 4.05716C10.4571 4.05716 10.8571 4.05716 11.2571 4.05716C11.3714 4.05716 11.4857 4.05716 11.6 4.05716C11.6 3.54287 11.6 3.08573 11.6 2.62859C11.6 2.40001 11.7143 2.22859 11.9428 2.17144C12.2286 2.1143 12.4571 2.28573 12.4571 2.62859C12.4571 3.02859 12.4571 3.42859 12.4571 3.82859C12.4571 3.94287 12.4571 4.00001 12.4571 4.1143C12.9143 4.1143 13.3714 4.1143 13.8286 4.1143C13.9429 4.1143 14.0571 4.1143 14.1143 4.17144C14.2857 4.22859 14.4 4.40001 14.4 4.62859C14.3429 4.85716 14.1714 5.02859 13.9428 5.02859C13.5428 5.02859 13.2 5.02859 12.8 5.02859C12.6857 5.02859 12.5714 5.02859 12.4571 5.02859C12.4571 5.42859 12.4571 5.82859 12.4571 6.17144C12.4571 6.28573 12.4571 6.34287 12.4571 6.45716C12.4571 6.74287 12.2857 6.9143 12 6.9143C11.7714 6.9143 11.6 6.74287 11.6 6.45716C11.6 6.22859 11.6 6.00001 11.6 5.7143C11.6 5.42859 11.6 5.20001 11.6 4.9143Z"
          fill="#FF914D"
        />
        <path
          d="M12.4 20.0571C12.4 20.2286 12.4 20.3429 12.4 20.5143C12.4 20.8 12.2286 20.9714 12 21.0286C11.7714 21.0286 11.6 20.8571 11.5429 20.5714C11.5429 20.4571 11.5429 20.2857 11.5429 20.1143C11.2571 20.1143 10.9714 20.1143 10.7428 20.1143C10.1714 20.1143 9.77142 19.7143 9.71428 19.2C9.65714 18.9143 9.82856 18.6857 10.0571 18.6286C10.2857 18.5714 10.5143 18.7429 10.5714 19.0286C10.6286 19.2 10.6857 19.2571 10.8571 19.2571C11.6 19.2571 12.4 19.2571 13.1428 19.2571C13.3714 19.2571 13.4286 19.1429 13.4286 18.9714C13.4286 18.6286 13.4286 18.2857 13.4286 17.9429C13.4286 17.7143 13.3143 17.6 13.0857 17.6571C12.3428 17.6571 11.5428 17.6571 10.8 17.6571C10.1143 17.6571 9.65714 17.2 9.65714 16.5143C9.65714 16.1714 9.65714 15.7714 9.65714 15.4286C9.65714 14.8 10.0571 14.3429 10.7428 14.3429C10.9714 14.3429 11.2571 14.3429 11.5429 14.3429C11.5429 14.1714 11.5429 14.0571 11.5429 13.8857C11.5429 13.6 11.7143 13.4286 11.9428 13.3714C12.1714 13.3714 12.3428 13.5429 12.4 13.8286C12.4 13.9429 12.4 14.1143 12.4 14.2857C12.6857 14.2857 12.9714 14.2857 13.2 14.2857C13.7714 14.2857 14.1714 14.6857 14.2286 15.2C14.2857 15.4857 14.1143 15.7143 13.8857 15.7714C13.6571 15.8286 13.4286 15.6571 13.3714 15.3714C13.3143 15.2 13.2571 15.1429 13.0857 15.1429C12.2857 15.1429 11.5428 15.1429 10.7428 15.1429C10.5714 15.1429 10.4571 15.2571 10.4571 15.4286C10.4571 15.7714 10.4571 16.1143 10.4571 16.4571C10.4571 16.6857 10.5714 16.8 10.8 16.8C11.5428 16.8 12.3428 16.8 13.0857 16.8C13.8286 16.8 14.2286 17.2571 14.2286 17.9429C14.2286 18.2857 14.2286 18.6857 14.2286 19.0286C14.2286 19.6571 13.8286 20.1143 13.1428 20.1143C12.9714 20.0571 12.7429 20.0571 12.4 20.0571Z"
          fill="#FF914D"
        />
      </svg>
    </div>
  );
};

export default Become1;
