import React from "react";

const EditAddressCard = ({title = "", placeholder1 = "", placeholder2 = ""}) => {
  return (
    <div className="container mx-auto mt-[25px] border-[1px] rounded-2xl border-[#FF914D] h-[300px] py-[24px] px-[16px]">
      <div>
        <p className="text-lg font-Glacial text-[#111]">{title}</p>
        <p className="flex">
          <span
            className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
          ></span>
        </p>
      </div>
      <div>
        <div className="pt-[26px]">
        <span className="pl-[16px]">Spanish</span>
        </div>
        <div className="pt-[10px]">
          <input
            className="w-full flex-start h-[50px] outline-none rounded-full ps-[25px] pe-[25px] font-Glacial  text-[18px] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5] placeholder:text-[#ABABAB]"
            type="text"
            placeholder={placeholder1}
          />
        </div>
      </div>
      <div>
        <div className="pt-[26px]">
        <span className="pl-[16px] pt-[26px]">English</span>
        </div>
        <div className="pt-[10px]">
          <input
            className="w-full flex-start h-[50px] outline-none rounded-full ps-[25px] pe-[25px] font-Glacial  text-[18px] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5] placeholder:text-[#ABABAB]"
            type="text"
            placeholder={placeholder2}
          />
        </div>
      </div>
    </div>
  );
};

export default EditAddressCard;
